import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';

function ScannerCloseButton({onClick}) {
  const theme = useTheme();

  return (
    <IconButton
      variant="contained"
      color="primary"
      onClick={onClick}
      style={{ border: '1px solid', borderRadius: 5, borderColor: theme.palette.primary.main }}
      >
      <CloseIcon />
    </IconButton>
  );
};

export default ScannerCloseButton;