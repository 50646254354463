import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const ConfirmationDialog = ({ open, title, text, onConfirm, onCancel }) => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} variant="contained" color="primary" autoFocus>
                    {t("Accept")}
                </Button>
                <Button onClick={onCancel} color="primary">
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;