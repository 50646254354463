import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button,  AppBar,
Toolbar, IconButton, Typography, List, ListItemButton, ListItemText, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const SelectPackageDialog = ({ open, onClose, value }) => {
    const { t } = useTranslation();
    const [packageList, setPackageList] = useState(value);
    const [opened, setOpened] = useState(open);

    useEffect(() => {
        setPackageList(value);
    }, [value]);

    useEffect(() => {
        setOpened(open);
    }, [open]);

    const handleClose = (event, reason) => {
        onClose(null);
    };

    const handleListItemClick = (pck) => {
        onClose(pck);
    }

    return (
        <Dialog open={opened} onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {t("Select package")}
                </Typography>
              </Toolbar>  
            </AppBar>
            <DialogContent>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {packageList?.map((p, i) => (
                        <>
                        <ListItemButton key={i}
                        onClick={(event) => handleListItemClick(p)}>
                            <ListItemText
                                secondary={p.companyName}
                                primary={p.name}
                            />
                        </ListItemButton>
                        <Divider />
                        </>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SelectPackageDialog;