import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, DialogTitle,  Button, DialogContent, DialogActions } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

function SessionTimeout({open}) {
    
    const { t } = useTranslation();
    const { loginWithRedirect } = useAuth0();

    const handleDialogClose = () => {
        return;
    };

    return (
        <>
            <Dialog open={open} onClose={handleDialogClose}>
                <DialogTitle>{t('Session has expired')}</DialogTitle>
                <DialogContent>
                <Alert severity="warning">
                    {t('The session has expired. Please login again.')}
                </Alert>
                </DialogContent>
                <DialogActions>
                    <Button primary onClick={loginWithRedirect} color="primary">
                    {t('Login')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SessionTimeout;