import React, { useState, useEffect } from 'react';
import Map from './Map';
import { Table, TableBody, TableCell, TableRow, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AddPackageStepSummary = ({ value }) => {
    const { t } = useTranslation();
    const [ editPackage, setEditPackage ] = useState(value);
    const [ location, setLocation ] = useState({
        address: value?.address,
        position: value?.position
    });

    useEffect(() => {   
        setEditPackage(value);
        setLocation({
            address: value?.address,
            position: value?.position
        });
    }, [value]);

    return (
        <div>
            {editPackage && (
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell><strong>{t("Company")}:</strong></TableCell>
                            <TableCell>{editPackage?.companyName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><strong>{t("Barcode")}:</strong></TableCell>
                            <TableCell>{editPackage?.barcode}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><strong>{t("Type")}:</strong></TableCell>
                            <TableCell>{editPackage?.type}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><strong>{t("Name")}:</strong></TableCell>
                            <TableCell>{editPackage?.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><strong>{t("Address")}:</strong></TableCell>
                            <TableCell>{editPackage?.address}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><strong>{t("Available date")}:</strong></TableCell>
                            <TableCell>{editPackage?.date?.toLocaleDateString()}</TableCell>
                        </TableRow>
                        {editPackage?.pickupDate && (
                            <TableRow>
                                <TableCell><strong>{t("Pickup date")}:</strong></TableCell>
                                <TableCell>
                                    <Chip label={editPackage.pickupDate.toLocaleDateString()} 
                                        style={{ backgroundColor: '#A9F78D' }} />
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell><strong>{t("Comments")}:</strong></TableCell>
                            <TableCell>
                                {(editPackage?.comments ?? '').split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
            <br />
            <Map location={location} />
        </div>
    );
};

export default AddPackageStepSummary;