import MapPlatformService from './mapPlatformService';

export const geocode = async (address) => {
    
    const geocoder = MapPlatformService.platform.getSearchService();
    var geocodingParameters = { q: address };
    const result = await geocoder.geocode(geocodingParameters);
    return result?.items || [];
};

export const reverseGeocode = async (location) => {
    const geocoder = MapPlatformService.platform.getSearchService();
    var geocodingParameters = {
        at: `${location.lat},${location.lng}`
    };
    const result = await geocoder.reverseGeocode(geocodingParameters);
    if (result.items.length === 0) {
        return null;
    }
    const fstResult = result.items[0];
    return {
        address: fstResult.address.label,
        position: {
            lat: fstResult.position.lat,
            lng: fstResult.position.lng
        }
    }
}
