import './App.css';
import { Auth0Provider } from '@auth0/auth0-react';
import MainView from './components/MainView';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { UserProvider } from './contexts/UserProvider';

function App() {
  
  const theme = createTheme({
    palette: {
      primary: {
        main: '#142544',
      },
      secondary: {
        main: '#d9ff46',
      },
    },
  });
  
  return (
    <Auth0Provider
      domain="dev-qml6okhwaockeegs.us.auth0.com"
      clientId="c3H6QvLYdQDRqzukHkbXV6cCfwTQ7i9O"
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
      cacheLocation="localstorage"
      >
    <div className="App">
    <ThemeProvider theme={theme}>
      <UserProvider>
        <MainView />
      </UserProvider>
    </ThemeProvider>
    </div>
    </Auth0Provider>
  );
}

export default App;