import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, CircularProgress, Stack, Grid, Table, TableBody, TableCell, TableRow} from '@mui/material';
import ApiService from '../services/apiService';
import { useTranslation } from 'react-i18next';
import SelectPackageDialog from './SelectPackageDialog';
import { UserContext } from '../contexts/UserProvider';
import AppSnackbar from './AppSnackbar';
import ScannerDialog from './scanner/ScannerDialog';

function AddPackageStepBarcode({value, onChange, isNew}) {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [barcode, setBarcode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [packageValue, setPackageValue] = useState(value);
    const [packageOptions, setPackageOptions] = useState(null);
    const [openSelectDialog, setOpenSelectDialog] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState(null);
    const [openScannerDialog, setOpenScannerDialog] = useState(false);

    useEffect(() => {
        setBarcode(null);
        setPackageValue(value);
    }, [value, userInfo]);

    const handleSearch = async (barcode) => {
        setLoading(true);
        try {
            const searchResult = await ApiService.searchBarcode(userInfo?.idToken, barcode);
            if (searchResult?.length > 0) {
                if (searchResult.length === 1) {
                    selectPackage(searchResult[0]);
                } else {
                    setPackageOptions(searchResult);
                    setOpenSelectDialog(true);
                }
            } else {
                setSnackbarInfo({
                    message: t('Package not found.'),
                    severity: 'warning',
                });
                selectPackage(null);
            }
        } catch (error) {
            setSnackbarInfo({ message: t(error.message), severity: error.severity});
            selectPackage(null);
        }
        setLoading(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch(barcode).then();
        }
    };

    const selectPackage = (pck) => {
        const newPackageValue = {
            ...packageValue,
            barcode: pck ? pck.barcode : null,
            companyId: pck ? pck.companyId : null,
            companyName: pck ? pck.companyName : null,
            type: pck ? pck.type : null,
            name: pck ? pck.name : null
        };
        setPackageValue(newPackageValue);
        onChange(newPackageValue);
    }

    const onSelectPackage = (pck) => {
        setOpenSelectDialog(false);
        if (pck) {
            selectPackage(pck);
        }
    }

    const handleScan = () => {
        setOpenScannerDialog(true);
    }

    const onScannerDialogClose = (result) => {
        setOpenScannerDialog(false);
    }

    const handleOnScan = (result) => {
        setOpenScannerDialog(false);
        setBarcode(result);
        handleSearch(result).then();
    }

    return (
        <>
        <Grid container spacing={2}>
            {isNew && (
                <>
                    <Grid item xs={12}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                            <TextField
                                fullWidth
                                label={t("Barcode")}
                                value={barcode ?? ''}
                                onChange={(e) => setBarcode(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <Button onClick={() => handleSearch(barcode)} 
                                disabled={!barcode || loading}
                                variant="contained" color="primary">
                                {!loading && t('Search')}
                                {loading && <CircularProgress size={24} />}
                            </Button>
                            <Button onClick={handleScan} 
                                disabled={loading}
                                variant="contained" color="secondary">
                                {t('Scan')}
                            </Button>
                        </Stack>
                    </Grid>
                </>
            )}
            {packageValue?.barcode && (
                <Grid item xs={12}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row"><strong>Company</strong></TableCell>
                                <TableCell>{packageValue?.companyName}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row"><strong>Barcode</strong></TableCell>
                                <TableCell>{packageValue?.barcode}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row"><strong>Type</strong></TableCell>
                                <TableCell>{packageValue?.type}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row"><strong>Name</strong></TableCell>
                                <TableCell>{packageValue?.name}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            )}
        </Grid>
        <SelectPackageDialog open={openSelectDialog} onClose={onSelectPackage} value={packageOptions} />
        <ScannerDialog open={openScannerDialog} onClose={onScannerDialogClose} onScan={handleOnScan} />
        <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
        </>
    );
}

export default AddPackageStepBarcode;