import axios from 'axios';
import ApiError from './apiError';

const apiUrl = process.env.REACT_APP_VERSION !== 'local' ?
    `https://pack.onerbox.com/service/${process.env.REACT_APP_VERSION}/api` :
    'http://localhost:5010/api';

export default class ApiServiceHttp {

    static async getUserCompanies(idToken) {
        try
        {
            const response = await axios.get(`${apiUrl}/pack/user/usercompanies`, getHeader(idToken));
            if (response.data?.status === "SUCCESS") {
                const companies = response.data.users.map(u => ({ id: u.companyId, name: u.companyName }));
                if (companies.length > 0) {
                    return companies;
                }
            }
        }
        catch (error)
        {
            if (error.response?.status === 403) {
                return null; // Return null to show the not valid user message
            } else if (error.response?.status === 401) {
                throw new ApiError("Unauthorized.", "error");
            }
        }
        throw new ApiError("Error validating user.","error");
    };

    static async getPackages(idToken) {
        try
        {
            const response = await axios.get(`${apiUrl}/pack/packageaction`, getHeader(idToken));    
            if (response.data?.status === "SUCCESS")
            {
                return response.data.packageActionResponses.map(parsePackageDto);
            }
        }
        catch (error)
        {
            if (error.response?.status === 401) {
                throw new ApiError("Unauthorized.","error");
            }
        }
        throw new ApiError("Error retrieving packages.","error");
    };

    static async getPackage(idToken, packageId) {
        try
        {
            const response = await axios.get(`${apiUrl}/pack/packageaction/${packageId}`, getHeader(idToken));    
            if (response.data?.status === "SUCCESS")
            {
                const p = response.data.packageActionResponse;
                return parsePackageDto(p);
            }
        }
        catch (error)
        {
            if (error.response?.status === 401) {
                throw new ApiError("Unauthorized.","error");
            }
        }
        throw new ApiError("Error retrieving the package.","error");
    }

    static async savePackage(idToken, packageData) {
        try
        {
            const dto = getPackageDto(packageData);
            await axios.post(`${apiUrl}/pack/packageaction`, dto, getHeader(idToken));    
            return true;
        }
        catch (error)
        {
        }
        throw new ApiError("Error saving the package.","error");
    }

    static async updatePackage(idToken, packageData) {
        try
        {
            const dto = getPackageDto(packageData);
            await axios.put(`${apiUrl}/pack/packageaction/${packageData.id}`, dto, getHeader(idToken));    
            return true;
        }
        catch (error)
        {
            if (error.response?.status === 401) {
                throw new ApiError("Unauthorized.","error");
            } else if (error.response?.data?.error === "PackageActionInPlanning") {
                throw new ApiError("Package action in planning.","warning");
            }
        }
        throw new ApiError("Error updating the package.","error");
    }

    static async deletePackage(idToken, packageData) {
        try
        {
            await axios.delete(`${apiUrl}/pack/packageaction/${packageData.id}`, getHeader(idToken));    
            return true;
        }
        catch (error)
        {
            if (error.response?.status === 401) {
                throw new ApiError("Unauthorized.","error");
            } else if (error.response?.data?.error === "PackageActionInPlanning") {
                throw new ApiError("Package action in planning.","warning");
            }
        }
        throw new ApiError("Error deleting the package.","error");
      }
  

    static async searchBarcode(idToken, barcode) {
        try
        {
            const response = await axios.get(`${apiUrl}/pack/package/${barcode}`, getHeader(idToken));    
            if (response.data?.status === "SUCCESS")
            {
                return response.data.packageResponses.map(p => ({
                    barcode: p.barcode,
                    type: p.containerTypeId,
                    name: p.containerType,
                    companyId: p.companyId,
                    companyName: p.companyName,
                }));
            }
        }
        catch (error)
        {
            if (error.response?.status === 401) {
                throw new ApiError("Unauthorized.","error");
            } else if (error.response?.status === 404) {
                throw new ApiError("Package not found.","warning");
            } else if (error.response?.data?.error === "PackageActionAlreadyExists") {
                throw new ApiError("Package action already exists.","warning");
            }
        }
        throw new ApiError("Error searching package.","error");
    }
}

function getHeader(idToken) {
    return {
        headers: {
            Authorization: `Bearer ${idToken}`,
        }
    };
}

function getPackageDto(packageData) {
    return {
        id: packageData.id,
        companyId: packageData.companyId,
        barcode: packageData.barcode,
        availablePickupDate: packageData.date,
        locationAddress: packageData.address,
        locationLatitude: packageData.position.lat,
        locationLongitude: packageData.position.lng,
        description: packageData.comments
    };
}

function parsePackageDto(p) {
    return {
        id: p.id,
        barcode: p.barcode,
        type: p.containerTypeId,
        name: p.containerType,
        date: p.availablePickupDate ? new Date(p.availablePickupDate) : null,
        confirmed: p.containerBlocked,
        address: p.locationAddress,
        position: { lat: p.locationLatitude, lng: p.locationLongitude },
        companyId: p.companyId,
        companyName: p.companyName,
        pickupDate: p.containerPickupDate ? new Date(p.containerPickupDate) : null,
        comments: p.description
    }
}