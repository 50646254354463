import React, { useContext, useState, useEffect } from 'react';
import Header from './Header';
import PickupPackages from './PickupPackages';
import EmailNotVerifiedDialog from './EmailNotVerifiedDialog';
import WelcomeDialog from './WelcomeDialog';
import InvalidUserDialog from './InvalidUserDialog';
import { UserContext } from '../contexts/UserProvider';
import SessionTimeout from './SessionTimeout';
import AppSnackbar from './AppSnackbar';
import { useTranslation } from 'react-i18next';

function MainView() {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [ snackbarInfo, setSnackbarInfo ] = useState(null);

    useEffect(() => {
        if (userInfo?.error) {
            setSnackbarInfo({
                message: t(userInfo.error?.message),
                severity: userInfo.error?.severity
            });
        }
    }, [userInfo?.error]);

    return (
        <>
            <Header />
            {userInfo?.companies && (<PickupPackages />)}
            <WelcomeDialog open={!userInfo} />
            <EmailNotVerifiedDialog open={userInfo?.noVerifiedEmail} />
            <InvalidUserDialog open={userInfo?.noValid} />
            <SessionTimeout open={userInfo?.isTokenExpired} />
            <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
        </>
    );
}

export default MainView;