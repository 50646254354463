import React, { useEffect, useRef } from 'react';
import MapPlatformService from '../services/mapPlatformService';
import { reverseGeocode } from '../services/geocoderService';

function Map({location, onChange}) {
    const mapRef = useRef(null);
    const map = useRef(null);  

    useEffect(() => {
        if (!mapRef.current) return;
        if (!map.current) initMap(mapRef, map, location, onChange);
        setPosition(map, location);
    }, [location, onChange]);

    return (
        <div ref={mapRef} style={{ width: '100%', height: '400px' }} />
    );
}

function initMap(mapRef, map, location, onChange) {
    const engineType = window.H.Map.EngineType['HARP'];
    const defaultLayers = MapPlatformService.platform.createDefaultLayers({ engineType });
    var baseMap = defaultLayers.vector.normal.map;

    const newMap = new window.H.Map(
        mapRef.current,
        baseMap,
        {
            engineType,
            pixelRatio: window.devicePixelRatio,
            center: location?.position ?? { lat: 52.5, lng: 13.4 },
            zoom: 10
        }
    );

    new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(newMap));

    // Create ui
    const ui = new window.H.ui.UI.createDefault(newMap, defaultLayers, 'en-US');
    ui.removeControl('zoom');
    ui.removeControl('mapsettings');
    ui.removeControl('scalebar');

    // Add zoom with 'fractionalZoom: false'
    ui.addControl('zoom', new window.H.ui.ZoomControl({
        fractionalZoom: false,
        alignment: window.H.ui.LayoutAlignment.RIGHT_BOTTOM
    })); 

    // Add Mapssettings
    var ms = new window.H.ui.MapSettingsControl({
        baseLayers: [
            { label: 'layers.normal', layer: baseMap },
            { label: 'layers.satellite', layer: defaultLayers.raster.satellite.map },
        ],
        layers: [
            { label: 'layer.traffic', layer: defaultLayers.vector.traffic.map },
        ],
        alignment: 'bottom-right'
    });
    ui.addControl('mapsettings', ms);

    // Add Scale bar
    ui.addControl('scalebar', new window.H.ui.ScaleBar({
        alignment: 'bottom-right'
    }));

    newMap.addEventListener('tap', async evt => {
        if (typeof onChange !== 'function') return;
        const l = newMap.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
        const location = await reverseGeocode(l)
        if (location) onChange(location);
    });

    map.current = newMap;
}

function setPosition(map, location) {
    map.current.removeObjects(map.current.getObjects());
    if (location?.position) {
        map.current.setCenter(location?.position);
        map.current.addObjects([
        new window.H.map.Marker(location.position, {
            icon: getMarkerIcon('#142544')
        }),
    ]);
    }
}

function getMarkerIcon(color) {
    var markupTemplate =
    `<svg xmlns="http://www.w3.org/2000/svg" width="19px" height="24px">
        <path stroke="white" d="m9.56351,0.93802c-5.46024,0 -8.34636,4.17386 -8.50237,8.84858c0,2.75475 0.78003,4.67472 2.4181,6.42774l6.08426,6.42774l6.08426,-6.42774c1.63807,-1.75302 2.3401,-3.58952 2.3401,-6.42774c-0.15601,-4.78324 -2.96413,-8.84858 -8.42436,-8.84858c0,0 0.00001,0.0625 0.00001,0z" fill="${color}" id="svg_3" stroke-width="1"/>
     </svg>`;
    return new window.H.map.Icon(markupTemplate, {
        anchor: {
            x: 9,
            y: 24
        }
    });
}

export default Map;