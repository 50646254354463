import React from 'react';
import { IconButton } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { reverseGeocode } from '../services/geocoderService';

function CurrentLocationButton({onChange}) {
    const handleClick = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (p) => {
                const position = {
                    lat: p.coords.latitude,
                    lng: p.coords.longitude
                };
                const location = await reverseGeocode(position)
                if (location) onChange(location);
            },
            error => {
                console.log("Error gelocating", error);
              },
            { enableHighAccuracy: true }
            );
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    };

    return (
        <IconButton color="primary" onClick={handleClick}>
            <MyLocationIcon />
        </IconButton>
    );
}

export default CurrentLocationButton;