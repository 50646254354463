class MapPlatformService {
    static instance = null;
    platform = null;

    constructor() {
        if (MapPlatformService.instance) {
            return MapPlatformService.instance;
        }

        this.platform = new window.H.service.Platform({
            apikey: "7lZHAmwuKTq-ebl69-d6wk7uihEjFHGFlFAEJD7BTtU",
            useCIT: true,
            useHTTPS: true
        });

        MapPlatformService.instance = this;
    }

    // Methods

}

const mapPlatformServiceInstance = new MapPlatformService();
export default mapPlatformServiceInstance;