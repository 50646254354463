import { useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { useTheme } from '@mui/material';

function CameraSelector({deviceId, onChange}) {
  const [devices, setDevices] = useState([]); 
  const [selectedDeviceId, setSelectedDeviceId] = useState(deviceId);
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      const devices = await getVideoInputDevices();
      setDevices(devices);
    })();
  }, []);

  const handleOnClick = () => {   
    const currentIndex = devices.findIndex(device => device.deviceId === selectedDeviceId);
    const newIndex = (currentIndex + 1) % devices.length;
    handleSetDeviceId(devices[newIndex].deviceId);
  }

  const handleSetDeviceId = (deviceId) => { 
    setSelectedDeviceId(deviceId);
    onChange(deviceId);
  }

  function getVideoInputDevices() {
    return navigator.mediaDevices.getUserMedia({ video: true })
    .then(stream => {
      stream.getTracks().forEach(track => track.stop());
      return navigator.mediaDevices.enumerateDevices();
    })
    .then(devices => {
      return devices.filter(device => device.kind === 'videoinput');
    })
    .then(videoDevices => {
      if (!deviceId) {
        handleSetDeviceId(videoDevices[0].deviceId);
      }
      return videoDevices;
    })
    .catch(err => console.error(err));
  }

  return (
    <>
    {devices?.length > 1 && (
      <IconButton
        variant="contained"
        color="primary"
        onClick={handleOnClick}
        style={{ border: '1px solid', borderRadius: 5, borderColor: theme.palette.primary.main }}
        >
        <FlipCameraAndroidIcon />
      </IconButton>
    )}
    </>
  );
};

export default CameraSelector;