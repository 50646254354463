import React,  { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Alert, Grid, TextField } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

// Locales for calendar
import 'dayjs/locale/en';
import 'dayjs/locale/ca';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';

export default function AddPackageStepDate({value, onChange}) {
    const { t, i18n } = useTranslation();
    const dayjs = require('dayjs');
    const locale = dayjs.locale(i18n.language)
    const [packageValue, setPackageValue] = useState(value);
    const [date, setDate] = useState(value?.date ? dayjs(value.date) : null); 
    const [invalidDate, setInvalidDate] = useState(false);
    const [comments, setComments] = useState(value?.comments ?? '');

    const handleDateChange = (date) => {
        if (date < dayjs().startOf('day')) {
            setInvalidDate(true);
            packageValue.date = null;
            setPackageValue(packageValue);
            setDate(null);
            onChange(packageValue);
        }
        else
        {
            setInvalidDate(false);
            packageValue.date = date.toDate();
            setPackageValue(packageValue);
            setDate(date);
            onChange(packageValue);
        }
    }

    const handleCommentChange = (event) => {
        packageValue.comments = event.target.value;
        setComments(event.target.value);
        setPackageValue(packageValue);
        onChange(packageValue);
    }

    return (
        <Grid container spacing={2}>
            {invalidDate && (
                <Grid item xs={12}>
                    <Alert severity="error">
                        {t('The selected date is not valid.')}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale} >
                    <DateCalendar label={t("Aviable date")} value={date} onChange={handleDateChange} />
                </LocalizationProvider>
                </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        label={t("Comments")}
                        value={comments}
                        onChange={handleCommentChange}
                    />
            </Grid>
        </Grid>
      );
}