import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Chip, TableContainer } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';

function PickupPackagesTable({ pickupPackages, onEditPackage }) {
    const { t } = useTranslation();

    return (
        <div>
            {pickupPackages &&
            <TableContainer>
                <Table>
                    <colgroup>
                        <col style={{width:'40%'}}/>
                        <col style={{width:'50%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'1%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>{t("Barcode")}</strong></TableCell>
                            <TableCell><strong>{t("Type")}</strong></TableCell>
                            <TableCell><strong>{t("Date")}</strong></TableCell>
                            <TableCell><strong>{t("Actions")}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pickupPackages.map(item => (
                            <TableRow key={item.barcode}>
                                <TableCell>{item.barcode}</TableCell>
                                <TableCell>{item.type}</TableCell>
                                <TableCell>
                                    {!item.pickupDate && (
                                       item.date ? item.date.toLocaleDateString() : ''  
                                    )}
                                    {item.pickupDate && (
                                    <Chip label={item.pickupDate.toLocaleDateString()} 
                                        style={{ backgroundColor: '#A9F78D' }} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {item.confirmed ? (
                                        <IconButton onClick={() => { onEditPackage(item) }}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={() => { onEditPackage(item)}}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </div>
    );
}

export default PickupPackagesTable;