import React, { createContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ApiService from '../services/apiService';

export const UserContext = createContext();

export function UserProvider({ children }) {
    const { isAuthenticated, getIdTokenClaims, user } = useAuth0();
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        if (isAuthenticated) {
            const info = {
                idToken: null,
                noVerifiedEmail: false,
                noValid: false,
                companies: null,
                error: false,
                user: user,
                tokenExpiration: null,
                isTokenExpired: false
            };
            getIdTokenClaims()
            .then(async claims => {
                info.idToken = claims.__raw;
                info.tokenExpiration = claims.exp;
                info.isTokenExpired = claims.exp * 1000 < new Date().getTime();
                if (!info.isTokenExpired) {
                    if(claims.email_verified) {
                        const companies = await ApiService.getUserCompanies(claims?.__raw);
                        if (companies?.length > 0) {
                            info.companies = companies;
                        } else {
                            info.noValid = true;
                        }
                    } else {
                        info.noVerifiedEmail = true;
                    }
                }
                setUserInfo(info);
            })
            .catch(error => {
                info.error = error;
                setUserInfo(info);
            });
        } else {
            setUserInfo(null);
        }
    }, [isAuthenticated, getIdTokenClaims, user]);

    useEffect(() => {
        if (userInfo?.tokenExpiration) {
            const timeToExpiration = userInfo.tokenExpiration * 1000 - new Date().getTime();
            const intervalId = setTimeout(() => {
                setUserInfo(prevUserInfo => ({
                    ...prevUserInfo,
                    isTokenExpired: true
                }));
            }, timeToExpiration);
            return () => clearTimeout(intervalId);
        }
    }, [userInfo?.tokenExpiration]);

    return (
        <UserContext.Provider value={userInfo}>
            {children}
        </UserContext.Provider>
    );
}