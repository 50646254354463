
export default class ApiServiceMock {

    static packages = [];

    static async getUserCompanies(idToken) {
      const base64Url = idToken.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const userInfo = JSON.parse(window.atob(base64));
      if (userInfo?.email === 'raulropero@onerbox.com') {
          return [
              { id: '1', name: 'Company with name 1' },
              { id: '2', name: 'Company with name 2' },
            ];
          };
      return null;
    };

    static async getPackages(idToken) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 500));
      if (this.packages.length > 0) return this.packages;
      for (let i = 0; i < 10; i++) {
        const companyName = 'Company with name ' + (i + 1);
        const id = i;
        const barcode = Math.floor(Math.random() * 1000000); // Generate a random barcode
        const type = Math.random() > 0.5 ? 'Type1' : 'Type2'; // Randomly choose between 'Type1' and 'Type2'
        const name = 'Big container';
        const confirmed = Math.random() > 0.5 ? true : false; // Randomly choose between 'Yes' and 'No'
        const address = 'Madrid';
        const position = { lat: 40.416775, lng: -3.703790 };
        const date = new Date(2023, 11, 25); // Set a fixed pickup date
        const pickupDate = confirmed ? new Date(2023, 11, 25) : null; // Set a fixed pickup date
        this.packages.push({ id, companyName, barcode, type, name, date, confirmed, address, position, pickupDate });
      }
      return this.packages;
    };

    static async getPackage(idToken, packageId) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 500));
      let p = this.packages.find(p => p.id === packageId);
      return p;
    }

    static async savePackage(idToken, packageData) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 500));
      this.packages.push(packageData);
      return true;
    }

    static async updatePackage(idToken, packageData) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 500));
      this.packages = this.packages.map(p => p.barcode === packageData.barcode ? packageData : p);
      return true;
    }

    static async deletePackage(idToken, packageData) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 500));
      this.packages = this.packages.filter(p => p.barcode !== packageData.barcode);
      return true;
    }

    static async searchBarcode(idToken, barcode) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 500));
      if (barcode === '0') return null;
      const result = [
        {
          companyId: 'CompanyID1',
          companyName: 'Company with name 1',
          barcode: '1',
          type: 'Type1',
          name: 'Package1',
        }
      ];
      if (barcode === '2') {
        result.push({
          companyId: 'CompanyID2',
          companyName: 'Company with name 2',
          barcode: '2',
          type: 'Type2',
          name: 'Package2',
        });
      }
      return result;
    }
}
