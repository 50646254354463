import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

function AppSnackbar({info, onClose}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    return (
        <>
        {info && (<Snackbar 
            open={info !== null} 
            autoHideDuration={info?.duration ?? (info?.severity === 'error' ? 5000 : 2000)} 
            anchorOrigin={{
                vertical: isSmallScreen ? 'bottom' : 'top',
                horizontal: 'center',
              }}
            onClose={onClose}
            >
            <Alert severity={info?.severity ?? 'error'} sx={{ width: '100%' }}>
                <span>{info?.message ?? t('Unknown error') }</span>
            </Alert>
        </Snackbar>
        )}
        </>
    );
}

export default AppSnackbar;