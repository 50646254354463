import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, IconButton, 
    Stepper, Step, StepLabel, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddPackageStepBarcode from './AddPackageStepBarcode';
import AddPackageStepDate from './AddPackageStepDate';
import AddPackageStepLocation from './AddPackageStepLocation';
import AddPackageStepSummary from './AddPackageStepSummary';
import ApiService from '../services/apiService';
import AppSnackbar from './AppSnackbar';
import DeleteButton from './ConfirmButton';
import { UserContext } from '../contexts/UserProvider';

const AddPackageForm = ({ open, onClose, onAccept, editPackage }) => {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [saving, setSaving] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [isStepValid, setIsStepValid] = useState(false);
    const [isNew, setIsNew] = useState(true);
    const [packageValue, setPackageValue] = useState(editPackage ?? {
        companyName: null,
        barcode: null,
        type: null,
        name: null,
        address: null,
        position: null,
        date: null,
        comments: null
    });

    const handleStepChange = (value) => {
        setPackageValue(value);
        updateIsStepValid(activeStep, value);
    };

    const steps = [
        { label: t('Package'), component: <AddPackageStepBarcode value={packageValue} onChange={handleStepChange} isNew={isNew} /> },
        { label: t('Location'), component: <AddPackageStepLocation value={packageValue} onChange={handleStepChange} /> },
        { label: t('Date'), component: <AddPackageStepDate value={packageValue} onChange={handleStepChange} /> },
        { label: t('Summary'), component: <AddPackageStepSummary value={packageValue} /> },
      ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        updateIsStepValid(activeStep + 1, packageValue);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        updateIsStepValid(activeStep - 1, packageValue);
    };

    const handleAdd = async () => {
        setSaving(true);
        try {
            if (isNew) {
                await ApiService.savePackage(userInfo.idToken, packageValue);
                setSnackbarInfo({message: t('Package added successfully.'), severity: 'success'});
            } else {
                await ApiService.updatePackage(userInfo.idToken, packageValue);
                setSnackbarInfo({message: t('Package updated successfully.'), severity: 'success'});
            }
            onAccept();
        } 
        catch (error) {
            setSnackbarInfo({message: t(error.message), severity: error.severity});
        }
        setSaving(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    const handleDelete = async () => {
        setSaving(true);
        try {
            await ApiService.deletePackage(userInfo.idToken, packageValue);
            setSnackbarInfo({message: t('Package deleted successfully.'), severity: 'success'});
            onAccept();
        } 
        catch (error) {
            setSnackbarInfo({message: t(error.message), severity: error.severity});
        }
        setSaving(false);
    }

    const isLastStep = () => { 
        return activeStep === steps.length - 1;
    }

    const updateIsStepValid = (index, value) => {
        switch (index) {
            case 0:
                setIsStepValid(value?.barcode);
                break;
            case 1:
                setIsStepValid(value?.address);
                break;
            case 2:
                setIsStepValid(value?.date);
                break;
            default:
                setIsStepValid(true);
                break;
        }
    }

    useEffect(() => {
        if (open) {
            setPackageValue(editPackage ?? {
                companyName: null,
                barcode: null,
                type: null,
                name: null,
                address: null,
                position: null,
                date: null,
                comments: null
            });
            setActiveStep(editPackage ? steps.length - 1 : 0);
            updateIsStepValid(0, editPackage);
            setIsNew(!editPackage);
        }
    }, [open, editPackage, steps.length]);

    return (
        <>
            <Dialog fullScreen open={open} onClose={handleClose}>
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {isNew ? t('Add package') : t('Edit package') + ' ' + editPackage?.barcode}
                    </Typography>
                </Toolbar>  
                </AppBar>
                <DialogContent>
                    <Stepper sx={{  pt: { xs: 1, sm: 2 }, pb: { xs: 3, sm: 0 } }} activeStep={activeStep} alternativeLabel>
                    {steps.map((step) => (
                        <Step key={step.label}>
                            <StepLabel>{step.label}</StepLabel>
                        </Step>
                    ))}
                    </Stepper>
                    <Container sx={{ padding: { xs: 0, sm: 5 } }}>
                        {steps[activeStep].component}
                    </Container>
                </DialogContent>
                <DialogActions>
                    {activeStep > 0 && (
                        <Box sx={{ flexGrow: 1 }}>
                        <Button onClick={handleBack} disabled={saving}>
                            {t('Back')}
                        </Button>
                        </Box>
                    )}
                    {!isLastStep() && (
                        <Button variant="contained" color="primary" onClick={handleNext} 
                        disabled={!isStepValid} >
                        {t('Next')}
                    </Button>
                    )}
                    {isLastStep() && !isNew && !saving && (
                        <DeleteButton 
                            variant="contained"
                            color="error"
                            onConfirm={handleDelete}
                            msgTitle={t("Delete package")}
                            msgText={t("Are you sure you want to delete this package?")}>
                            {t('Delete')}
                        </DeleteButton>
                    )}
                    {isLastStep() && (
                        <Button variant="contained" color="primary" onClick={handleAdd} disabled={saving}>
                            {!saving && (isNew ? t('Add') : t('Save')) }
                            {saving && <CircularProgress size={24} />}
                        </Button>
                    )}
                    <Button onClick={onClose} color="primary" disabled={saving}>{t('Cancel')}</Button>
                </DialogActions>
            </Dialog>
            <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
        </>
    );
};

export default AddPackageForm;