import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button,  AppBar,
Toolbar, IconButton, Typography, Container } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import AddPackageStepSummary from './AddPackageStepSummary';

const ViewPackageForm = ({ open, onClose, editPackage }) => {
    const { t } = useTranslation();
    const [packageValue, setPackageValue] = useState(editPackage);

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    useEffect(() => {
        if (open) {
            setPackageValue(editPackage);
        }
    }, [open, editPackage]);

    return (
        <Dialog fullScreen open={open} onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Package {editPackage?.barcode}
                </Typography>
              </Toolbar>  
            </AppBar>
            <DialogContent>
                <Container sx={{ padding: { xs: 0, sm: 5 }}}>
                    <AddPackageStepSummary value={packageValue} />
                </Container>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewPackageForm;