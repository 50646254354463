import { useZxing } from "react-zxing";
import styled from "@mui/material/styles/styled";

function Scanner({onScan, deviceId}) {
  const { ref } = useZxing({
    deviceId,
    onDecodeResult(result) {
      handleOnScan(result.getText());  
    },
  });

  const handleOnScan = (data) => {
    const audio = new Audio(process.env.PUBLIC_URL + '/beep.mp3');
    audio.play();
    onScan(data);
  }

  const ScannerOverlay = styled('div')({
    position: 'absolute',
    top: '53%',
    left: 0,
    width: '100%',
    height: '2',
    border: '1px solid red',
    boxSizing: 'border-box',
  });

  return (
   <>
       {deviceId && (<video ref={ref} style={{ width: '100%', height: '100%' }}/>)}
      <ScannerOverlay />
    </>
  );
};

export default Scanner;