import ApiServiceHttp from './apiServiceHttp';
import ApiServiceMock from './apiServiceMock';

export default class ApiService {

    static service = process.env.REACT_APP_ENV === 'test' ? ApiServiceMock : ApiServiceHttp;

    static async getUserCompanies(idToken) {
        return await this.service.getUserCompanies(idToken);
    };

    static async getPackages(idToken) {
        return await this.service.getPackages(idToken);
    };

    static async getPackage(idToken, packageId) {
        return await this.service.getPackage(idToken, packageId);
    }

    static async savePackage(idToken, packageData) {
        return await this.service.savePackage(idToken, packageData);
    }

    static async updatePackage(idToken, packageData) {
        return await this.service.updatePackage(idToken, packageData);
    }

    static async deletePackage(idToken, packageData) {
        return await this.service.deletePackage(idToken, packageData);
    }

    static async searchBarcode(idToken, barcode) {
        return await this.service.searchBarcode(idToken, barcode);
    }
}
