import { useState, useEffect } from "react";
import { Dialog, Box } from '@mui/material';
import Scanner from "./Scanner";
import CameraSelector from "./CameraSelector";
import ScannerCloseButton from "./ScannerCloseButton";

function ScannerDialog({open, onClose, onScan}) {
  const initialDeviceId = localStorage.getItem('selectedDeviceId');
  const [selectedDeviceId, setSelectedDeviceId] = useState(initialDeviceId);

  useEffect(() => {
    if (selectedDeviceId) {
      localStorage.setItem('selectedDeviceId', selectedDeviceId);
    }
  }, [selectedDeviceId]);

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Box display="flex" justifyContent="space-between" width="100" p={1}>
        <ScannerCloseButton onClick={onClose} />
        <CameraSelector deviceId={selectedDeviceId} onChange={setSelectedDeviceId}  />
      </Box>
      <Scanner onScan={onScan} deviceId={selectedDeviceId}  />
    </Dialog>
  );
};

export default ScannerDialog;