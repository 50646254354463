import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ConfirmationDialog from './ConfirmationDialog';

const ConfirmButton = ({ onConfirm, msgTitle, msgText, variant, color, children }) => {
    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleOnClick = () => {
        setConfirmOpen(true);
    };

    const handleConfirm = async () => {
        onConfirm();
    };

    return (
        <>
            <Button variant={variant} color={color} onClick={handleOnClick}>
                {children}
            </Button>
            <ConfirmationDialog
                open={confirmOpen}
                title={msgTitle}
                text={msgText}
                onConfirm={handleConfirm}
                onCancel={() => setConfirmOpen(false)}
            />
        </>
    );
};

export default ConfirmButton;