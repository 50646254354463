import React, { useState, useEffect, useContext } from 'react';
import PickupPackagesTable from './PickupPackagesTable';
import { Button, Box, LinearProgress, Container } from '@mui/material';
import ApiService from '../services/apiService';
import AddPackageForm from './AddPackageForm';
import ViewPackageForm from './ViewPackageForm';
import useMediaQuery from '@mui/material/useMediaQuery';
import PickupPackagesTableMobile from './PickupPackagesTableMobile';
import { UserContext } from '../contexts/UserProvider';
import AppSnackbar from './AppSnackbar';
import { useTranslation } from 'react-i18next';

function PickupPackages() {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [pickupPackages, setPickupPackages] = useState(null);
    const [isAddPackageFormOpen, setIsAddPackageFormOpen] = useState(false);
    const [isViewPackageFormOpen, setIsViewPackageFormOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [snackbarInfo, setSnackbarInfo] = useState(null);

    useEffect(() => {
        requestPickupPackages(userInfo?.idToken);
    }, [userInfo?.idToken]); 

    const requestPickupPackages = async (idToken) => {
        setIsLoading(true);
        try {
            const response = await ApiService.getPackages(idToken);
            setPickupPackages(response);
        }
        catch(error) {
            setPickupPackages(null);
            setSnackbarInfo({ message: t(error.message), severity: error.severity});
        }
        setIsLoading(false);
    }
        
    const showAddPackageForm = () => {
        setIsAddPackageFormOpen(true);
    }

    const hideAddPackageForm = () => {
        setIsAddPackageFormOpen(false);
    }

    const showViewPackageForm = () => {
        setIsViewPackageFormOpen(true);
    }

    const hideViewPackageForm = async () => {
        setIsViewPackageFormOpen(false);
        requestPickupPackages(userInfo?.idToken);
    }

    const acceptAddPackageForm = () => {
        hideAddPackageForm();
        requestPickupPackages(userInfo?.idToken);
    };

    const handleOnEditPackage = async (editPackage) => {
        setIsLoading(true);
        try {
            const loadedPackage = await ApiService.getPackage(userInfo?.idToken, editPackage.id);
            setSelectedPackage(loadedPackage);
            loadedPackage.confirmed ? showViewPackageForm() : showAddPackageForm();
        } 
        catch(error) {
            setSnackbarInfo({ message: t(error.message), severity: error.severity});
        }
        setIsLoading(false);
    }

    const handleAddPackage = () => {
        setSelectedPackage(null);
        showAddPackageForm();
    }

    return (
        <>
        { isLoading && (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>)}
            <Container>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <h1>{t("Pickup packages")}</h1>
                    <Button variant='contained' onClick={handleAddPackage}>{t("Add")}</Button>
                </Box>
                {!isSmallScreen ?
                    <PickupPackagesTable pickupPackages={pickupPackages} onEditPackage={handleOnEditPackage} /> :
                    <PickupPackagesTableMobile pickupPackages={pickupPackages} onEditPackage={handleOnEditPackage} />
                }
            </Container>
            <AddPackageForm 
                open={isAddPackageFormOpen} 
                onClose={hideAddPackageForm} 
                onAccept={acceptAddPackageForm} 
                editPackage={selectedPackage} />
            {selectedPackage && (<ViewPackageForm 
                open={isViewPackageFormOpen} 
                onClose={hideViewPackageForm}
                editPackage={selectedPackage} />
            )}
            <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
        </>
    );
}

export default PickupPackages;