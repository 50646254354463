import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { geocode } from '../services/geocoderService';
import { useTranslation } from 'react-i18next';

const AddressTextField = ({location, onChange}) => {

    const { t } = useTranslation();
    const [options, setOptions] = useState([]);
    const [result, setResult] = useState(location); // To avoid passing undefind to Autocomplete

    useEffect(() => {
        setResult(location);
    }, [location]);

    const handleInputChange = async (event, value) => {
        if (value) {
            const geocodes = await geocode(value);
            const distinctGeocodes = geocodes.filter((result, index, self) =>
                index === self.findIndex((t) => (
                    t.title === result.title
                ))
            );
            const locations = distinctGeocodes.map((result) => {
                return {
                    address: result.title,
                    position: {
                        lat: result.position.lat,
                        lng: result.position.lng
                    }
                }
            });
            setOptions(locations);
        } else {
            setOptions([]);
        }
    };

    const handleChange = (event, value) => {
        setResult(value);
        onChange(value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            if (options.length > 0) {
                handleChange(null, options[0]);
            }
        }
    };

    return (
        <Autocomplete
            freeSolo
            fullWidth
            options={options}
            filterOptions={(x) => x}
            getOptionLabel={(option) => typeof option?.address === 'string' ? option.address : ''}
            onInputChange={handleInputChange}
            onChange={handleChange}
            value={result}
            autoComplete
            includeInputInList
            filterSelectedOptions
            renderInput={(params) => {
                params.inputProps.onKeyDown = handleKeyDown;
                return (
                <TextField 
                    {...params} 
                    label={t('Address')} 
                    variant="outlined"
                    placeholder="Address"
                    />
            )}}
        />
    );
};

export default AddressTextField;