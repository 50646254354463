import React, { useState, useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import AddressTextField from './AddressTextField';
import CurrentLocationButton from './CurrentLocationButton';
import Map from './Map';

const AddPackageStepLocation = ({value, onChange}) => {
    const [packageValue, setPackageValue] = useState(value);
    const [location, setLocation] = useState({
        address: value?.address,
        position: value?.position
    });

    const handleChange = (newLocation) => {
        setLocation(newLocation);
        packageValue.address = newLocation.address;
        packageValue.position = newLocation.position;
        setPackageValue(packageValue);
        onChange(packageValue);
    };

    useEffect(() => {
        setLocation({
            address: value.address,
            position: value.position
        });
    }, [value]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                    <AddressTextField 
                        name="address"
                        location={location}
                        onChange={handleChange}
                        />
                    <CurrentLocationButton onChange={handleChange} />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Map location={location} onChange={handleChange} />
            </Grid>            
        </Grid>
    );
};

export default AddPackageStepLocation;